import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const CarouselLegacyLoadable = loadable(() => import('./CarouselLegacy'), {
  resolveComponent: components => components.CarouselLegacy
});

export const CarouselLegacy = WithLazyHydration(CarouselLegacyLoadable, {
  name: 'CarouselLegacy'
});
